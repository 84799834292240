import intl from 'react-intl-universal'
import { LOCALE } from './constant'

const locales = {
  en: require('./locales/en.json'),
  jp: require('./locales/jp.json'),
  de: require('./locales/de.json'),
  es: require('./locales/es.json'),
  fr: require('./locales/fr.json'),
  it: require('./locales/it.json'),
  pt: require('./locales/pt.json'),
  ru: require('./locales/ru.json'),
  tr: require('./locales/tr.json'),
  kr: require('./locales/kr.json'),
}

export default ({ element, props }: { element: any; props: any }) => {
  const { i18n } = props.pageContext
  const { locale = LOCALE.EN } = i18n ?? {}
  intl.init({
    currentLocale: locale,
    locales,
    fallbackLocale: LOCALE.EN,
  })
  return element
}

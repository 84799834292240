const I18N_LOCALE = ['en', 'es', 'pt', 'de', 'tr', 'jp', 'it', 'fr', 'ru', 'kr']

const Locale = Object.freeze({
  EN: 'en',
  ES: 'es',
  PT: 'pt',
  DE: 'de',
  TR: 'tr',
  JP: 'jp',
  IT: 'it',
  FR: 'fr',
  RU: 'ru',
  KR: 'kr',
})

module.exports.Locale = Locale

module.exports.I18N_LOCALE = I18N_LOCALE

module.exports.LocalePath = I18N_LOCALE.reduce((result, locale) => {
  result.push(`/${locale}/`)
  return result
}, [])

module.exports.LOCALE = Object.freeze({
  EN: 'en',
  ES: 'es',
  PT: 'pt',
  DE: 'de',
  TR: 'tr',
  JP: 'jp',
  IT: 'it',
  FR: 'fr',
  RU: 'ru',
  KR: 'kr',
})

module.exports.LOCALE_CODE_MAP = new Map([
  [this.LOCALE.EN, 'en-US'],
  [this.LOCALE.JP, 'ja-JP'],
])

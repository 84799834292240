import WrapPage from '@foxrenderfarm/internals/i18n/wrap-page'

import '@foxrenderfarm/common/src/layout/global.css'

export const wrapPageElement = WrapPage

export const onClientEntry = () => {
  // function redirect_url() {
  //   const { host, href } = window.location
  //   if (host === 'ray.foxrenderfarm.com') {
  //     window.location.href = href.replace(/ray\.foxrenderfarm\.com/, 'foxrenderfarm.com')
  //   }
  // }
  // redirect_url()

  function getCookie(name) {
    const parts = document.cookie.split(/;\s*/)
    for (let i = 0; i < parts.length; i++) {
      let partMap = parts[i].split('=')
      if (partMap[0] === name) {
        return partMap[1]
      }
    }
    return null
  }
  function setCookie(name, value, Days) {
    const OneDay = 24 * 60 * 60
    const maxAge = OneDay * Days
    document.cookie = `${name}=${value};max-age=${maxAge};expires=${new Date(
      Date.now() + maxAge * 1000,
    )};path=/;domain=.foxrenderfarm.com`
  }

  const blockComefrom = getCookie('blockComefrom')
  if (!blockComefrom || Date.now() > +blockComefrom) {
    let referrer = document.referrer || document.URL
    if (document.referrer && window.location.search.includes('source=')) {
      if (referrer.includes('&')) {
        referrer = `${referrer}&${window.location.search.slice(1)}`
      } else {
        referrer += window.location.search
      }
    }
    // 记录24小时内不可覆盖
    setCookie('blockComefrom', new Date().setDate(new Date().getDate() + 1), 1)
    // 记录保持7天
    setCookie('comefrom', referrer, 7)
  }
}

export const onInitialClientRender = () => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-WF7HJ9N');
  `
  document.head.appendChild(script)

  const noScript = document.createElement('noscript')
  noScript.innerHTML = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WF7HJ9N" height="0" width="0" style="display: none; visibility: hidden" aria-hidden="true"></iframe>
  `
  document.body.appendChild(noScript)
}
